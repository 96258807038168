@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.content {
  padding: 30px;
  text-align: center;
  width: 100%;

  @include media-up(md) {
    padding: 70px 30px;
  }

  @media (min-width: 1151px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  color: $color-green;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 31px;

  @include media-up(md) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 43px;
  }
}

.selects {
  margin-bottom: 16px;

  @include media-up(md) {
    display: flex;
    justify-content: center;
    margin-bottom: 53px;
  }
}

.select {
  width: 100%;

  @include media-up(md) {
    width: 330px;
  }

  &:first-child {
    margin-right: 25px;
    margin-bottom: 16px;

    @include media-up(md) {
      margin-right: 25px;
      margin-bottom: 0;
    }
  }
}

.row {
  width: 100%;

  @include media-up(md) {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.button {
  width: 100%;
  margin-top: 32px;

  @include media-up(md) {
    margin-top: 25px;
  }
}