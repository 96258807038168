@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.room {
  position: relative;
}

.sale {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: $color-white;
  background-color: $color-salad;
  padding: 6px 10px;

  @include media-up(md) {
    padding: 12px 19px;
    font-size: 24px;
    line-height: 29px;
  }
}

.content {
  display: grid;
  background-color: rgba($color-salad, 0.15);

  @include media-up(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "img info "
      "panel panel";
  }

  @include media-up(lg) {
    grid-template-columns: auto 1fr;
  }

  @include media-up(xl) {
    grid-template-rows: 1fr auto;
    grid-template-columns: auto 1fr 1fr;
    grid-template-areas:
      "img info vars"
      "img panel panel";
  }
}

.preview {
  overflow: hidden;
  min-height: auto;
  height: 220px;
  display: flex;

  @include media-up(md) {
    min-height: 290px;
    grid-area: img;
  }

  @include media-up(lg) {
    min-height: 440px;
    width: 435px;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder {
  width: 50%;
  height: 50%;
  object-fit: contain;
  margin: auto;
}

.info {
  padding: 12px 10px;

  @include media-up(md) {
    grid-area: info;
  }

  @include media-up(lg) {
    padding: 30px 40px;
  }
}

.title {
  text-transform: uppercase;
  color: $color-salad;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;

  @include media-up(md) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 12px;
  }

  @include media-up(lg) {
    font-size: 32px;
    line-height: 39px;
  }
}

.options {
  margin-bottom: 20px;

  @include media-up(md) {
    margin-bottom: 25px;
  }
}

.item {
  font-size: 14px;
  line-height: 17px;
  color: $color-green;
  display: flex;
  align-items: center;

  @include media-up(md) {
    font-size: 16px;
    line-height: 19px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    @include media-up(md) {
      margin-bottom: 15px;
    }
  }
}

.icon {
  margin-right: 18px;
  width: 20px;
  height: 20px;
  object-fit: contain;

  @include media-up(md) {
    width: 30px;
    height: 30px;
  }
}

.description {
  color: $color-gray;
  font-size: 13px;
  line-height: 16px;

  @include media-up(md) {
    font-size: 16px;
    line-height: 19px;
  }

  @include media-up(lg) {
    font-size: 18px;
    line-height: 22px;
  }
}

.variants {
  width: 100%;
  order: 1;
  margin-top: 5px;
  margin-right: 10px;

  @include media-up(sm) {
    margin-top: 0;
    margin-right: auto;
    order: unset;
    width: auto;
  }

  @include media-up(xl) {
    margin-right: 0;
    grid-area: vars;
    padding-top: 3px;
  }

  .select {
    @include media-up(md) {
      width: auto;
    }

    @include media-up(lg) {
      width: 25vw;
    }
  }

  .selected {
    font-size: 15px;

    //@include media-up(lg) {
    //  justify-content: unset;
    //}

    &:after {
      margin-left: 16px;
    }
  }

  .option {
    color: $color-green;
    font-size: 15px;
    text-align: left;
  }
}

.total {
  display: flex;
  flex-direction: column;

  //@include media-up(lg) {
  //  width: 92px;
  //}

  &Old {
    color: $color-lightgray;
    font-size: 12px;
    line-height: 22px;

    @include media-up(md) {
      font-size: 15px;
      line-height: 22px;
    }

    @include media-up(lg) {
      font-size: 20px;
      line-height: 30px;
    }
  }

  &Current {
    font-size: 16px;
    line-height: 22px;

    @include media-up(md) {
      font-size: 20px;
      line-height: 22px;
    }

    @include media-up(lg) {
      font-size: 32px;
      line-height: 30px;
    }
  }

  &WrapperTop {
    .totalCurrentTop {
      color: #d9d9d9;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-decoration: line-through;
    }
  }
}

.book {
  text-transform: uppercase;
  margin-left: 10px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  padding: 15px 10px;
  background: #c9f1d5;
  color: $color-green;

  @include media-up(md) {
    margin-left: 18px;
    font-size: 24px;
    line-height: 29px;
    padding: 10px;
  }

  @include media-up(lg) {
    font-size: 28px;
    line-height: 34px;
    padding: 20px;
  }

  &:disabled {
    color: rgba($color-green, 0.6);
    cursor: not-allowed;
  }
}

.panel {
  color: $color-white;
  background-color: $color-green;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 10px 10px;

  @include media-up(sm) {
    padding: 0 0 0 10px;
  }

  @include media-up(md) {
    grid-area: panel;
    padding: 0 0 0 20px;
  }

  @include media-up(lg) {
    padding: 0 0 0 40px;
  }

  @include media-up(xl) {
    justify-content: flex-end;
  }
}

.count {
  display: flex;
  align-items: center;
  margin-right: auto;

  //@include media-up(sm) {
  //  margin-right: 20px;
  //}
  //
  //@include media-up(md) {
  //  margin-right: 40px;
  //}

  @include media-up(lg) {
    margin-right: 85px;
  }

  .select {
    width: 50px;

    @include media-up(md) {
      width: 70px;
    }

    @include media-up(xl) {
      width: 92px;
    }
  }

  &Text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-left: 10px;

    @include media-up(md) {
      font-size: 24px;
      line-height: 29px;
      margin-left: 18px;
    }

    @include media-up(lg) {
      margin-left: 30px;
      font-size: 28px;
      line-height: 34px;
    }
  }
}

.empty {
  margin-top: 24px;
  font-size: 16px;
  line-height: 1.2;
  color: $color-gray;

  @include media-up(xl) {
    font-size: 18px;
    margin-top: 0;
    padding: 30px 16px;
  }
}

.selected {
  height: 35px;
  padding: 2px 4px;

  @include media-up(md) {
    padding: 2px 10px 2px 18px;
  }

  @include media-up(xl) {
    height: 50px;
    padding: 13px 20px 13px 30px;
  }
}
