@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.container {
  display: flex;
  flex-direction: column;

  @include media-up(md) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.makeReservation {
  color: $color-white;
  background-color: $color-salad;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  @include media-up(md) {
    margin-left: 3.47vw;
    background: rgba($color-salad, 0.8);
    width: 296px;
  }

  @include media-up(lg) {
    width: 350px;
  }
}

.content {
  padding: 0 20px 20px 20px;
  display: flex;
  flex: 1 1 100%;
  min-width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.header {
  font-weight: 500;
  font-size: 1.111em;
  background: rgba(85, 197, 182, 0.9);
  padding: 10px 65px;
  margin-bottom: 22px;
  text-align: center;
}

.datesRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}

.dateColumn {
  display: flex;
  flex: 0 0 46%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(85, 197, 182, 0.6);
  color: $color-white;
  padding: 15px 10px;
}

.fill {
  width: 100%;
}

.text {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.111em;
}

.title {
  flex: 1 1 100%;
  white-space: nowrap;
}

.date {
  font-weight: 600;
  font-size: 3.333em;
}

.optionsRow {
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
  display: none;
}

.optionsColumn {
  display: flex;
  flex: 0 0 46%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.optionTitle {
  width: 100%;
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 1.111em;
}

.countContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 17px;
  background: rgba(85, 197, 182, 0.6);
}

.count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 1.333em;
}

.decrement {
  width: 18px;
  height: 16px;
}

.input {
  font-size: 1.111em;
  color: #ffffff;
  background: rgba(85, 197, 182, 0.6);
  padding: 12px 17px;
  width: 100%;
  // margin-bottom: 15px;
  display: none;
}

.displayFlex {
  display: flex;
}

.list {
  &::-webkit-scrollbar {
    &-thumb {
      background-color: $color-white;
    }

    &-track {
      background-color: #6ecab0;
    }
  }
}

.selected {
  justify-content: center;
  position: relative;
  padding: 12px;
  background-color: rgba($color-green, 0.6);
  color: $color-white;

  &:after {
    position: absolute;
    right: 12px;
    border-color: rgba($color-white, 0.7) transparent transparent transparent;
  }
}

.option {
  background-color: #6ecab0;
  text-align: center;
  color: $color-white;
  padding: 13px;

  &:hover {
    background-color: darken(#6ecab0, 5%);
  }
}

.error {
  border-bottom: 1px solid red;
}

.inputError {
  color: red;
  font-size: 14px;
  line-height: 17px;

  @include media-up(md) {
    font-size: 18px;
    line-height: 22px;
  }
}

.inputSuccess {
  color: $color-input;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;

  @include media-up(md) {
    font-size: 18px;
    line-height: 22px;
  }
}

.textError {
  color: red;
}

.inputPromo {
  font-size: 1.111em;
  color: #ffffff;
  background: rgba(85, 197, 182, 0.6);
  padding: 12px 17px;
  width: 70%;
  margin-bottom: 0px;
  display: none;
}

.inputPromoDisabled {
  &:not([type="checkbox"]):not([type="radio"]):not([role="combobox"]) {
    color: #ffffff;
    background-color: $color-lightgray;
  }
  font-size: 1.111em;

  padding: 12px 17px;
  width: 70%;
  margin-bottom: 0px;
  display: none;
}

.promocodeWrapper {
  width: 100%;
  display: flex;
}

.applyPromocode {
  width: 35%;
  height: auto;
  // margin-bottom: 15px;
}

.inputWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}
