@import "../utils/variables";

body {
	font-family: $main-font;
	min-width: 320px;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.no-scroll {
		overflow: hidden;
	}
}

main {
	flex: 1
}

.font-bold {
	font-weight: bold;
}