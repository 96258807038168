@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.result {
  margin-bottom: 16px;
}

.item {
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 30px;
  position: relative;

  @include media-up(md) {
    font-size: 20px;
    line-height: 30px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 4px;
    border-left: 8px solid rgba($color-black, 0);
    border-right: 8px solid rgba($color-black, 0);
    border-top: 12px solid $color-green;
    transition: transform 0.3s;

    @include media-up(md) {
      bottom: 8px;
    }
  }

  &.active {
    &:before {
      transform: rotate(-180deg);
    }
  }
}

.room {
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  &Info {
    color: $color-green;
  }

  &Name {
    color: $color-salad;
  }

  &Price {
    color: $color-gray;
    white-space: nowrap;
  }
}

.details {
  overflow: hidden;
  padding-left: 30px;
}

.info {
  padding-top: 13px;

  @include media-up(sm) {
    display: flex;
  }

  &Item {
    font-size: 15px;
    line-height: 18px;
    color: $color-salad;
    display: flex;
    align-items: center;

    @include media-up(md) {
      font-size: 18px;
      line-height: 22px;
    }

    &:not(:last-child) {
      margin-bottom: 13px;

      @include media-up(sm) {
        margin-right: 15px;
        margin-bottom: 0;
      }

      @include media-up(lg) {
        margin-right: 24px;
      }
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
      margin-right: 12px;

      @include media-up(md) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.dates {
  padding-top: 20px;

  @include media-up(md) {
    padding-top: 15px;
  }
}

.date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  line-height: 24px;
  color: $color-salad;

  @include media-up(md) {
    font-size: 18px;
  }
}