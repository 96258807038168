@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.button {
  height: 50px;
  padding: 13px;
  background-color: $color-green;
  color: $color-white;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  transition: background-color 0.3s;

  @include media-up(md) {
    height: auto;
  }

  &:hover {
    background-color: darken($color-green, 5%);
  }
}

.buttonDisabled {
  height: 50px;
  padding: 13px;
  background-color: $color-semigray;
  color: $color-white;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  @include media-up(md) {
    height: auto;
  }
}
