@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.mainMenu {
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  /* height: 11vh;
	height: 8vw; */
  // min-height: 104px;
  // height: calc((3vw + 6vh));
  width: 100%;
  color: #77817a;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  height: 44px;

  @include media-up(md) {
    height: 81px;
  }

  @media (min-width: 1151px) {
    height: 104px;
  }
}

.logoWrapper {
  img {
		max-height: 44px;

		@include media-up(md) {
			max-height: 81px;
		}

		@media (min-width: 1151px) {
			max-height: 104px;
		}
    // max-height: 104px;
  }
}
