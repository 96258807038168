@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: $color-salad;
  width: 100%;
  max-width: 100%;
  padding: 10px 16px;
  color: $color-white;

  @include media-up(md) {
    padding: 14px 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: 1151px) {
    padding: 22px 6.5vw;
  }
}

.restartImg {
  width: 14px;
  height: 14px;
  object-fit: cover;
  margin-right: 11px;

  @include media-up(md) {
    width: 19px;
    height: 19px;
  }

  @media (min-width: 1151px) {
    margin-right: 15px;
    width: 37px;
    height: 37px;
  }
}

.startOverRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  @include media-down(sm) {
    grid-column: 2;
    grid-row: 2;
    justify-content: flex-end;
  }
}

.underline {
  text-decoration: underline;
  color: $color-white;
}

.text {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  @include media-up(md) {
    font-size: 14px;
    line-height: 17px;
  }

  @media (min-width: 1151px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.brand {
  .brandLink {
    text-decoration: none;
    cursor: pointer;
    color: $color-white;

    &:active,
    :visited {
      color: $color-white;
    }
  }
  @include media-down(sm) {
    display: flex;
    flex-direction: column;
    grid-row: span 2;

    span {
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}

.modify {
  @include media-down(sm) {
    grid-column: 2;
    margin-bottom: 9px;
    display: flex;
    justify-content: flex-end;
  }
}
