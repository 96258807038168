@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.item {
  padding: 16px;
  background-color: rgba($color-salad, 0.15);
  display: grid;
  grid-gap: 20px;
  align-items: center;
  font-size: 18px;
  line-height: 22px;

  @include media-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-up(md) {
    padding: 20px 24px;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 24px;
    line-height: 29px;
  }

  @include media-up(lg) {
    padding: 26px 40px 26px 55px;
    grid-template-columns: 2fr 2fr 1fr 1fr;
  }

  &:not(:last-child) {
    margin-bottom: 16px;

    @include media-up(md) {
      margin-bottom: 26px;
    }
  }

  &Title {
    max-width: 250px;
    color: $color-green;
    grid-column: 1;
    grid-row: 1;
  }

  &Text {
    color: $color-salad;

    @include media-up(sm) {
      grid-column: 1;
      grid-row: 2;
    }

    @include media-up(lg) {
      grid-row: 1;
      grid-column: 2;
    }
  }

  &Price {
    color: $color-green;

    @include media-up(sm) {
      text-align: right;
    }

    @include media-up(md) {
      text-align: center;
      grid-row: span 2;
    }

    @include media-up(lg) {
      grid-row: 1;
    }
  }
}

.add {
  width: 100%;
  margin-left: auto;

  @include media-up(sm) {
    max-width: 160px;
    grid-row: 2;
  }

  @include media-up(md) {
    grid-row: span 2;
  }

  @include media-up(lg) {
    grid-row: 1;
  }
}