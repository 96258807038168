@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.select {
  display: inline-flex;
  text-align: unset;
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

.text {
  height: 50px;
  cursor: pointer;
  padding: 13px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-salad;
  background-color: $color-input;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;

  @include media-up(lg) {
    font-size: 20px;
    line-height: 24px;
    padding: 13px 20px 13px 30px;
  }

  &:after {
    content: "";
    border-top: 12px solid;
    border-right: 8px solid;
    border-left: 8px solid;
    border-color: $color-green transparent transparent transparent;
    transition: transform 0.3s;
  }
}

.active {
  &:after {
    transform: rotate(-180deg);
  }
}

.list {
  position: absolute;
  top: calc(100% + 1px);
  left: 0;
  width: 100%;
  z-index: 1;
  overflow-y: auto;
  max-height: 180px;

  @include media-up(md) {
    max-height: 200px;
  }

  &::-webkit-scrollbar {
    width: 4px;

    &-thumb {
      background-color: $color-green;
    }

    &-track {
      background-color: $color-input;
    }
  }
}

.item {
  cursor: pointer;
  text-align: left;
  padding: 13px 17px;
  color: $color-salad;
  background-color: $color-input;
  font-size: 16px;
  line-height: 19px;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($color-input, 5%);
  }

  @include media-up(lg) {
    font-size: 20px;
    line-height: 24px;
    padding: 13px 20px 13px 30px;
  }
}

.selected {
  background-color: darken($color-input, 5%);
}