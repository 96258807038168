@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.content {
  padding: 30px;
  text-align: center;
  width: 100%;

  @include media-up(md) {
    padding: 70px 30px;
  }

  @media (min-width: 1151px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.title {
  color: $color-green;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;

  @include media-up(md) {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 50px;
  }

  @include media-up(lg) {
    margin-bottom: 20px;
  }
}

.text {
  color: $color-salad;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 30px;

  @include media-up(md) {
    font-size: 20px;
    line-height: 24px;
  }
}

.fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.field {
  display: flex;
  width: 100%;

  &:first-child {
    margin-bottom: 20px;
  }

  @include media-up(md) {
    width: auto;

    &:first-child {
      margin-right: 25px;
      margin-bottom: 0;
    }
  }
}

.asterisk {
  color: red;
  font-size: 18px;
  margin-left: 8px;
}

.input {
  width: 100%;

  @include media-up(md) {
    max-width: 330px;
  }
}

.note {
  margin-top: 8px;
  font-size: 14px;
  line-height: 17px;
  color: red;
  text-align: left;

  @include media-up(md) {
    margin-top: 30px;
    font-size: 20px;
    line-height: 24px;
    max-width: 685px;
    margin-left: auto;
    margin-right: auto;
  }
}

.row {
  width: 100%;

  @include media-up(md) {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.button {
  width: 100%;
  margin-top: 24px;

  @include media-up(md) {
    margin-top: 40px;
  }
}