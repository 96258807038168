@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.link {
	position: relative;
	color: $color-white;
	display: flex;
	flex-direction: column;
	background-color: $color-salad;
	width: 100%;
	transition: background-color 0.3s;
	padding: 8px 15px 5px 24px;

	@include media-up(md) {
		padding: 17px 30px 11px 50px;
	}

	@media (min-width: 1151px) {
		height: 100%;
		padding: 22px 6px 6px;
		margin-right: 2px;
		align-items: center;
	}
}

.link:last-child {
	margin-right: 0;
}

.active {
	background-color: $color-input;
	color: $color-green;

	@media (min-width: 1151px) {
		color: $color-white;
		background-color: $color-green;
	}
}

.title {
	font-size: 10px;
	line-height: 12px;
	margin-bottom: 6px;

	@include media-up(md) {
		margin-bottom: 12px;
		font-size: 14px;
		line-height: 19px;
	}

	@media (min-width: 1151px) {
		font-weight: 500;
		text-align: center;
	}

	@include media-up(xl) {
		font-size: 18px;
		line-height: 22px;
	}
}

.subtitle {
	font-size: 14px;
	line-height: 17px;

	@include media-up(md) {
		font-size: 20px;
		line-height: 26px;
	}

	@media (min-width: 1151px) {
		text-align: center;
	}

	@include media-up(xl) {
		font-size: 24px;
		line-height: 29px;
	}
}

.arrowDown {
	position: absolute;
	top: 40%;
	right: 15px;
	transform: rotate(-90deg) translateY(-50%);

	@include media-up(md) {
		right: 30px;
		top: 44%;
	}

	@media (min-width: 1151px) {
		transform: none;
		position: static;
		margin-top: auto;
		width: 18px;
		height: 16px;
	}
}

.text {
	font-size: 16px;
	line-height: 19px;

	@media (min-width: 1151px) {
		margin: auto;
	}
}
