@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.variant {
  cursor: pointer;
  padding: 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $color-green;

    .old,
    .current,
    .text {
      color: $color-white;
    }
  }
}

.radio {
  pointer-events: none;
  flex-shrink: 0;

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0.01em;
    height: 0.01em;
  }

  @include media-up(lg) {
    margin-right: 20px;
    display: block;
    box-shadow: inset 0 0 0 2px $color-lightgray;
    width: 21px;
    height: 21px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: $color-lightgray;

    &.checked {
      background-color: $color-green;
      box-shadow: inset 0 0 0 2px $color-lightgray, inset 0 0 0 4px $color-white;
    }
  }
}

.text {
  display: flex;
  flex-direction: column;
  color: $color-green;
  transition: color 0.3s;

  @include media-up(lg) {
    font-size: 20px;
    line-height: 30px;
    color: $color-salad;
  }
}

.price {
  margin-left: auto;
  min-width: 92px;
  display: flex;
  flex-direction: column;
  //align-self: flex-end;
}

.old {
  color: $color-semigray;
  font-size: 20px;
  line-height: 30px;
  transition: color 0.3s;
  text-decoration: line-through;
}

.current {
  color: $color-gray;
  font-size: 24px;
  line-height: 30px;
  transition: color 0.3s;
}

.selected {
  color: $color-green;
  background: rgba($color-white, 0.6);
  height: auto;
  padding: 5px 10px;

  @include media-up(lg) {
    padding: 10px 16px;
    justify-content: center;

    &:after {
      position: absolute;
      right: 12px;
    }
  }
}

.option {
  padding: 5px 10px;

  @include media-up(lg) {
    text-align: center;
    padding: 10px 16px;
  }
}

.list {
  max-height: 116px;

  @include media-up(md) {
    max-height: 136px;
  }

  @include media-up(lg) {
    max-height: 184px;
  }
}
