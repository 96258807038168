@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.book-now {
	.react-calendar {
		width: 100%;
		max-width: 100%;
		line-height: inherit;
		margin-bottom: 40px;

		@include media-up(md) {
			margin-bottom: 0;
			width: 350px;
		}
	}
	.react-calendar--doubleView {
		width: 700px;
	}
	.react-calendar--doubleView .react-calendar__viewContainer {
		display: flex;
		margin: -0.5em;
	}
	.react-calendar--doubleView .react-calendar__viewContainer > * {
		width: 50%;
		margin: 0.5em;
	}
	.react-calendar,
	.react-calendar *,
	.react-calendar *:before,
	.react-calendar *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		text-decoration: none;
	}
	.react-calendar button {
		margin: 0;
		border: 0;
		outline: none;
	}
	.react-calendar button:enabled:hover {
		cursor: pointer;
	}
	.react-calendar__navigation {
		display: flex;
		height: 44px;
		margin-bottom: 1em;
	}
	.react-calendar__navigation button {
		min-width: 44px;
		background: none;
	}
	.react-calendar__navigation button:disabled {
		background-color: transparent;
	}
	.react-calendar__navigation button:enabled:hover,
	.react-calendar__navigation button:enabled:focus {
		background-color: inherit;
	}
	.react-calendar__navigation__label {
		color: white;
		text-transform: uppercase;
		font-size: 1.333em;
	}
	.react-calendar__month-view__weekdays {
		text-align: center;
		text-transform: uppercase;
		font-weight: inherit;
		font-size: inherit;
	}
	.react-calendar__month-view__weekdays__weekday {
		padding: 0.5em;
		color: white;
		text-decoration: none;
	}
	.react-calendar__month-view__weekNumbers .react-calendar__tile {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.111em;
		font-weight: inherit;
	}
	.react-calendar__month-view__days__day--weekend {
		color: #d10000;
	}
	.react-calendar__month-view__days__day--neighboringMonth {
		color: #757575;
	}

	.react-calendar__year-view .react-calendar__tile,
	.react-calendar__decade-view .react-calendar__tile,
	.react-calendar__century-view .react-calendar__tile {
		padding: 2em 0.5em;
	}
	.react-calendar__tile {
		max-width: 100%;
		padding: 10px 6.66px;
		background: none;
		text-align: center;
		line-height: 0.888em;
		color: white;
		font-size: 1em;
	}
	.react-calendar__tile:disabled {
		pointer-events: none;
		background-color: transparent;
		color: gray;
		opacity: 0.6
	}
	.react-calendar__tile:enabled:hover,
	.react-calendar__tile:enabled:focus {
		background: rgba(85, 197, 182, 0.8);
		/* border-radius: 15px; */
	}
	.react-calendar__tile--hasActive {
		background: rgba(85, 197, 182, 0.8);
	}
	.react-calendar__tile--hasActive:enabled:hover,
	.react-calendar__tile--hasActive:enabled:focus {
		background: rgba(85, 197, 182, 0.8);
	}
	.react-calendar__tile--active {
		background: rgba(85, 197, 182, 0.8);
		/* TODO
    last and first child */
		/* border-radius: 15px; */
		color: white;
	}
	.react-calendar__tile--active:enabled:hover,
	.react-calendar__tile--active:enabled:focus {
		background: rgba(85, 197, 182, 0.8);
		/* TODO
    last and first child */
		/* border-radius: 15px; */
	}
	.react-calendar--selectRange .react-calendar__tile--hover {
		background: rgba(85, 197, 182, 0.8);
		/* TODO
    last and first child */
		/* border-radius: 15px; */
	}
}