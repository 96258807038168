@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.modal {
  max-width: 288px;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: $color-white;
  border-radius: 8px;
  padding: 32px 24px;
  margin: auto;

  @include media-up(xs) {
    max-width: 327px;
  }

  @include media-up(md) {
    max-width: 670px;
    padding: 32px;
  }

  @include media-up(lg) {
    max-width: 870px;
  }
}

.wrapper {
  z-index: 105;
  overflow-y: auto;
  padding: 24px 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.close {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  top: 10px;
  right: 10px;

  @include media-up(lg) {
    top: 16px;
    right: 16px;
  }
}