@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.item {
  font-size: 14px;
  line-height: 17px;
  color: $color-green;
  display: flex;
  align-items: center;

  @include media-up(md) {
    font-size: 16px;
    line-height: 19px;
  }

  &:not(:last-child) {
    margin-bottom: 10px;

    @include media-up(md) {
      margin-bottom: 15px;
    }
  }
}

.icon {
  margin-right: 18px;
  width: 20px;
  height: 20px;
  object-fit: contain;

  @include media-up(md) {
    width: 30px;
    height: 30px;
  }
}