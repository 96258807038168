@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.calendarContainer {
	background: rgba(125, 200, 148, 0.8);
	border: 8px solid #55c5b6;
	padding: 22px;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
	max-width: 420px;
	min-width: 320px;
	width: 29vw;

	display: none;
}
.displayBlock {
	display: block;
}

/* TABLET */
@media (max-width: 910px) {
	.calendarContainer {
		padding: 10px;
		/* max-width: 330px; */
	}
}
