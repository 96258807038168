@import "../utils/variables";
@import "../utils/mixins";

input {
  &:not([type="checkbox"]):not([type="radio"]):not([role="combobox"]) {
    height: 50px;
    padding: 13px 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-salad;
    background-color: $color-input;
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;

    @include media-up(md) {
      font-size: 20px;
      line-height: 24px;
    }

    &::placeholder {
      color: inherit;
    }
  }
}

textarea {
  padding: 13px 17px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-salad;
  background-color: $color-input;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  resize: none;

  @include media-up(md) {
    font-size: 20px;
    line-height: 24px;
  }

  &::placeholder {
    color: inherit;
  }

  &.auto {
    overflow-y: hidden;
  }
}