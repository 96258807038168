.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  max-width: 140px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.title {
  font-size: 26px;
  margin-bottom: 8px;
}

.text {
  font-size: 16px;
}