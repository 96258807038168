@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.calendar {
  padding: 30px;
  width: 100%;

  @include media-up(md) {
    padding: 60px 30px;
  }

  @include media-up(lg) {
    padding: 90px 30px;
  }

  @include media-up(xl) {
    padding: 90px 115px;
  }
}

.title {
  text-align: center;
  margin-bottom: 47px;
  text-transform: uppercase;
  color: $color-green;
  font-size: 18px;
  line-height: 20px;

  @media (min-width: 375px) {
    font-size: 20px;
    line-height: 24px;
  }

  @include media-up(md) {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 33px;
  }

  @include media-up(lg) {
    margin-bottom: 47px;
  }
}

.actions {
  width: 100%;

  @include media-up(sm) {
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

.button {
  width: 100%;
  margin-top: 20px;

  @include media-up(md) {
    margin-top: 25px;
  }
}