/* Media queries */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1240px,
  xxl: 1440px,
);

/* Fonts */
$main-font: Inter, -apple-system, system-ui, sans-serif;

/* Colors */
$color-black: #000000;
$color-white: #ffffff;
$color-bg: #060820;
$color-salad: #7DC894;
$color-green: #55C5B6;
$color-input: #ECF7EF;
$color-gray: #77817A;
$color-lightgray: #D9D9D9;
$color-semigray: #B3B1B1;