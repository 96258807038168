.mainButton {
	text-transform: uppercase;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background: rgba(85, 197, 182, 0.9);
	padding: 15px 45px;
	width: 100%;
	font-weight: 500;
	font-size: 1.111em;
	color: #ffffff;
}
