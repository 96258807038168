@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.container {
  width: 100%;
  margin: 0 auto;
  padding: 30px 10px 34px;
  position: relative;

  @include media-up(md) {
    max-width: 1332px;
    padding: 75px 46px;
  }
}

.rooms {
  @media (min-width: 375px) and (max-width: 767.98px){
    padding: 0 35px;
  }
}

.room {
  &:not(:last-child) {
    margin-bottom: 50px;

    @include media-up(lg) {
      margin-bottom: 70px;
    }
  }
}

.form {
  margin-bottom: 30px;

  @include media-up(md) {
    display: none;
  }
}