@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.calendar {
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-decoration: none;
  }

  .react-calendar {
    font-family: $main-font;

    &--doubleView {
      border: none;
      position: relative;
      margin: 0 auto 60px;

      @include media-up(sm) {
        width: 400px;
      }

      @include media-up(md) {
        margin: 0 auto 90px;
      }

      @include media-up(lg) {
        width: auto;
        padding: 0 74px;
      }
    }

    &__viewContainer {
      display: flex;
      margin: 0;

      @include media-down(md) {
        flex-direction: column;
      }

      & > * {
        width: 100%;
        margin: 0;

        @include media-up(lg) {
          width: 50%;
        }
      }
    }

    &__navigation {
      display: flex;
      justify-content: center;
      height: auto;
      margin-bottom: 30px;

      @include media-up(md) {
        margin-bottom: 35px;
      }

      @include media-up(lg) {
        margin-bottom: 44px;
        font-size: 36px;
        line-height: 44px;
      }

      &__label {
        pointer-events: none;
        text-transform: none;
        color: $color-salad;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-up(lg) {
          flex-direction: row;
          justify-content: space-around;
        }

        &__labelText {
          font-size: 16px;
          line-height: 19px;

          @include media-up(md) {
            font-size: 32px;
            line-height: 39px;
          }

          @include media-up(lg) {
            font-size: 36px;
            line-height: 44px;
          }
        }

        &__divider {
          display: none;
        }
      }

      &__arrow {
        width: 28px;
        height: 28px;

        @include media-up(md) {
          width: 40px;
          height: 40px;
        }

        @include media-up(lg) {
          width: 52px;
          height: 52px;
          position: absolute;
          top: 50%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__prev-button {
        left: 0;
      }

      &__next-button {
        right: 0;
      }

      button {
        min-width: 44px;
        background: none;

        &:hover,
        &:focus {
          background-color: inherit;
        }
      }
    }

    &__tile {
      color: $color-salad;
      font-size: 18px;
      line-height: 1.2;
      background: none;
      text-align: center;
      margin: 4px 0;
      padding: 3px;

      @include media-up(md) {
        font-size: 24px;
      }

      @include media-up(lg) {
        margin: 7px 0;
        padding: 6px 3px;
      }

      &:enabled:hover,
      &:enabled:focus {
        background: rgba($color-green, 0.4);
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.3;
        color: gray;
        pointer-events: none;
      }

      &--now {
        &.react-calendar__tile--hasActive {
          background: rgba($color-green, 0.4);
          border-radius: 20px 0 0 20px;
        }
      }

      &--hasActive {
        background: rgba($color-green, 0.4);

        &:enabled:hover,
        &:enabled:focus {
          background: rgba($color-green, 0.4);
        }
      }

      &--active {
        background: rgba($color-green, 0.4);
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;

        &:enabled:hover,
        &:enabled:focus {
          background: rgba($color-green, 0.4);
        }
      }

      &:not(.react-calendar__tile--range) {
        &:hover {
          border-radius: 20px;
        }
      }

      &.react-calendar__tile--hoverEnd {
        &:hover {
          border-radius: 0 20px 20px 0;
        }
      }

      &.react-calendar__tile--hoverStart {
        &:hover {
          border-radius: 20px 0 0 20px;
        }
      }

      &--range {
        &:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
          border-radius: 0;
        }

        &Start {

          &.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds {
            border-radius: 20px;

            &.react-calendar__tile--hoverStart {
              border-radius: 20px 0 0 20px;
            }

            &.react-calendar__tile--hoverEnd {
              border-radius: 0 20px 20px 0;
            }

            &.react-calendar__tile--hoverBothEnds {
              border-radius: 20px;
            }
          }
        }
      }

      &--range,
      &--hover {
        background: rgba($color-green, 0.4);

        &Start {
          border-radius: 20px 0 0 20px;
        }

        &End {
          border-radius: 0 20px 20px 0;
        }
      }
    }

    &__month-view {
      margin: 0;

      &:first-child {
        margin-bottom: 40px;

        @include media-up(lg) {
          margin-right: 22px;
          margin-bottom: 0;
        }
      }

      &__weekdays {
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 12px;
        font-weight: normal;

        @include media-up(lg) {
          margin-bottom: 18px;
        }

        &__weekday {
          font-size: 13px;
          line-height: 16px;
          text-transform: uppercase;
          color: $color-salad;
          padding: 6px;

          @include media-up(md) {
            font-size: 18px;
            line-height: 22px;
          }
        }
      }

      &__weekNumbers .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
      }

      &__days__day {
        &--weekend {
          color: $color-salad;
        }

        &--neighboringMonth {
          color: #B3B1B1;
          opacity: 0;
          pointer-events: none;
          margin: 0;
          padding: 0;
          height: 0;
        }
      }
    }

    &__year-view .react-calendar__tile,
    &__decade-view .react-calendar__tile,
    &__century-view .react-calendar__tile {
      padding: 2em 0.5em;
    }
  }
}
