@import "../../assets/scss/utils/variables";

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#7DC894, 0.5);
  backdrop-filter: blur(4px);
  z-index: 101;
}