@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.container {
  // background: url(../../assets/images/bg_large.jpg);
  background-size: cover;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content {
  width: 100%;
  height: 100%;

  @include media-up(md) {
    padding-right: 6.5vw;
    padding-left: 6.5vw;
    padding-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
}

.book {
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: $color-white;
  width: 100%;
  background-color: $color-salad;
}

.full {
  flex: 1;
  background-color: $color-salad;
}

.close {
  margin: 26px 30px 26px auto;
  display: block;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}
