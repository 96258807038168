@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.main {
  width: 100%;
  padding: 30px;

  @include media-up(md) {
    padding: 60px 30px 30px;
  }

  @include media-up(lg) {
    padding: 90px 30px 37px;
  }

  @include media-up(xl) {
    padding: 90px 90px 37px;
  }
}

.container {
  max-width: 1250px;
  margin: 0 auto;
}

.title {
  color: $color-salad;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: 500;

  @include media-up(md) {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  @include media-up(lg) {
    font-size: 32px;
    line-height: 39px;
  }
}

.button {
  margin-top: 20px;
  text-align: center;

  @include media-up(sm) {
    margin-top: 37px;
  }
}

.view {
  width: 100%;

  @include media-up(sm) {
    width: 280px;
  }
}

.total {
  width: 100%;
  padding: 0 30px;
  background-color: rgba($color-salad, 0.15);

  //@include media-up(lg) {
  //  padding: 0 90px;
  //}

  @include media-up(xl) {
    padding: 0 90px;
  }
}

.result {
  color: $color-salad;
  margin-left: auto;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include media-up(sm) {
    align-items: unset;
    flex-direction: row;
    justify-content: space-between;
  }

  @include media-up(lg) {
    width: 50%;
  }

  @include media-up(xl) {
    padding: 37px;
  }
}

.selection {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 16px;

  @include media-up(sm) {
    margin-bottom: 0;
  }

  @include media-up(md) {
    font-size: 24px;
    line-height: 29px;
  }
}

.rooms {
  margin-bottom: 10px;

  @include media-up(md) {
    margin-bottom: 19px;
  }
}

.price {
  display: flex;
  flex-direction: column;
  text-align: right;
  margin-bottom: 16px;
  
  @include media-up(sm) {
    text-align: unset;
    margin-bottom: 0;
  }
}

.text {
  font-size: 16px;
  line-height: 19px;
  color: $color-salad;
  margin-bottom: 10px;

  @include media-up(md) {
    margin-bottom: 14px;
    font-size: 20px;
    line-height: 24px;
  }
}

.value {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: $color-green;

  @include media-up(md) {
    font-size: 32px;
    line-height: 39px;
  }
}

.clear {
  font-size: 16px;
  line-height: 19px;
  color: $color-salad;
  cursor: pointer;
  align-self: flex-end;

  @include media-up(md) {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 24px;
  }
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}