@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.header {
	width: 100%;

	@media (max-width: 1150.98px) {
		position: relative;
		z-index: 1;
	}
}

.container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #ffffff;
	width: 100%;
	max-width: 100%;
	z-index: 1;
	position: relative;
}

.logoImg {
	padding: 6px 18px 2px 20px;
	background-color: $color-white;

	@include media-up(md) {
		padding: 11px 40px 3px 45px;
	}

	@media (min-width: 1151px) {
		padding: 16px 16px 10px 30px;
	}

	img {
		max-height: 44px;

		@include media-up(md) {
			max-height: 81px;
		}

		@media (min-width: 1151px) {
			max-height: 104px;
		}
	}
}

.nav {
	display: flex;
	flex: 1;

	@media (max-width: 1150.98px) {
		display: block;
		position: fixed;
		background-color: $color-white;
		left: 0;
		width: 100%;
		top: 95px;
		z-index: -1;
		max-height: calc(100% - 95px);
		overflow-y: auto;
	}

	@include media-down(sm) {
		top: 52px;
		max-height: calc(100% - 52px);
	}
}

.link {
	position: relative;
	color: $color-white;
	display: flex;
	flex-direction: column;
	background-color: $color-salad;
	width: 100%;
	transition: background-color 0.3s;
	padding: 8px 15px 5px 24px;

	@include media-up(md) {
		padding: 17px 30px 11px 50px;
	}

	@media (min-width: 1151px) {
		height: 100%;
		padding: 22px 6px 6px;
		margin-right: 2px;
		align-items: center;
	}
}

.list {

	@media (min-width: 1151px) {
		display: flex;
		flex: 1;
	}

	&Item {
		margin-top: 1px;

		@media (min-width: 1151px) {
			flex: 1;
			margin-top: 0;

			&:not(:last-child) {
				margin-right: 2px;
			}
		}
	}
}

.lang {
	width: 37px;
	height: 37px;
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;

	@include media-up(xl) {
		width: 40px;
		height: 40px;
	}
}

.langLink {
	color: $color-white;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: $color-salad;
	width: 100%;
	transition: background-color 0.3s;
	padding: 16px 6px 13px;
	height: 100%;
}

.activeLang {
	color: $color-white;
	background-color: $color-green;
}

.title {
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 6px;

	@include media-up(md) {
		margin-bottom: 12px;
	}

	@media (min-width: 1151px) {
		font-weight: 500;
		text-align: center;
	}

	@include media-up(xl) {
		font-size: 18px;
		line-height: 22px;
	}
}

.collapsedMenu {
	font-size: 14px;
	line-height: 17px;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $color-salad;
	width: 100%;
	cursor: pointer;

	@include media-up(md) {
		font-size: 20px;
		line-height: 24px;
		margin-right: 3px;
	}

	@media (min-width: 1151px) {
		.collapsedMenu {
			display: none;
		}
	}
}

.collapsedMenuText {
	margin-bottom: 3px;

	@include media-up(md) {
		margin-bottom: 6px;
	}
}

.collapsedMenuLink {
	border-bottom: 1px solid white;

	@include media-up(md) {
		border-width: 2px;
	}
}

.currency {
	width: 130px;
	flex-shrink: 0;
}

.backdrop {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background-color: rgba($color-green, 0.5);
	backdrop-filter: blur(6px);
	z-index: -2;

	@media (max-width: 1150.98px) {
		top: 95px;
		height: calc(100% - 95px);
	}

	@include media-down(sm) {
		top: 52px;
		height: calc(100% - 52px);
	}
}

.close {
	margin-top: 1px;
	padding: 8px;
	background-color: $color-salad;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-up(md) {
		padding: 15px;
	}

	&Icon {
		border-left: 4px solid rgba(0, 0, 0, 0);
		border-right: 4px solid rgba(0, 0, 0, 0);
		border-bottom: 6px solid rgba($color-white, 0.7);

		@include media-up(md) {
			border-left-width: 8px;
			border-right-width: 8px;
			border-bottom-width: 12px;
		}
	}
}