@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.content {
  width: 100%;
  padding: 30px 30px 40px;

  @include media-up(md) {
    padding: 75px 75px 45px;
  }

  @include media-up(lg) {
    padding: 90px;
  }
}

.container {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
}

.orders {
  margin-bottom: 50px;
}

.inputs {
  margin-bottom: 40px;
}

.orders,
.inputs {
  @include media-up(md) {
    margin-bottom: 44px;
  }

  @include media-up(lg) {
    margin-bottom: 0;
  }
}

.form {
  @include media-up(lg) {
    display: grid;
    grid-gap: 40px;
    grid-template-areas:
      "a b"
      "c c";
  }

  @include media-up(xl) {
    grid-template-areas: unset;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 80px;
  }
}

.conditions {
  @include media-between(lg, lg) {
    grid-area: c;
  }
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  color: $color-salad;

  @include media-up(md) {
    font-size: 32px;
    line-height: 39px;
    margin-bottom: 24px;
  }
}

.link {
  display: inline-block;
  font-size: 15px;
  line-height: 30px;
  text-decoration: underline;
  color: $color-green;
  margin-top: 10px;

  @include media-up(md) {
    font-size: 20px;
    margin-top: 35px;
  }

  &:hover {
    text-decoration: none;
  }
}

.total {
}

.text {
  color: $color-salad;
  line-height: 24px;
  font-size: 15px;

  @include media-up(md) {
    font-size: 18px;
  }
}

.subtotalValueNoPromocode {
  color: #b2b1b1;
  line-height: 24px;
  font-size: 15px;
  font-weight: 400;
  text-decoration-line: line-through;

  @include media-up(md) {
    font-size: 18px;
  }
}

.total {
  display: flex;
  justify-content: space-between;

  &Text {
    margin-right: auto;
  }
}

.subtotal {
  @extend .total;
  margin-bottom: 22px;

  &Text {
    @extend .totalText;
  }

  &Value {
    text-align: end;
    min-width: 78px;

    @include media-up(md) {
      min-width: 163px;
    }

    @include media-up(lg) {
      min-width: 85px;
    }
  }

  &Old {
    color: $color-semigray;
  }
}

.input {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  textarea,
  input {
    &:not([type="checkbox"]):not([type="radio"]) {
      width: 97%;
    }
  }
}

.required {
  flex-shrink: 0;
  color: red;
}

.select {
  width: 97%;
}

.selected,
.option {
  padding: 13px 17px;
}

.condition {
  @include media-up(xl) {
    padding-top: 63px;
  }
}

.checkbox {
  margin-bottom: 25px;
  position: relative;
  padding-left: 40px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @include media-up(md) {
    padding-left: 38px;

    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }

  @include media-up(lg) {
    &:not(:last-of-type) {
      margin-bottom: 18px;
    }
  }

  &Text {
    font-size: 15px;
    line-height: 18px;
    color: $color-salad;

    @include media-up(md) {
      font-size: 20px;
      line-height: 24px;
    }

    a {
      color: $color-green;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  [type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 0.01em;
    height: 0.01em;
  }

  &Label {
    font-size: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($color-salad, 0.15);

    @include media-up(md) {
      width: 22px;
      height: 22px;
    }

    &.checked {
      background-color: $color-salad;

      &:after {
        content: "";
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 6px;
        height: 10px;
        border-right: 2px solid $color-white;
        border-bottom: 2px solid $color-white;
      }
    }
  }
}

.info {
  margin-top: 18px;
  padding-left: 40px;

  @include media-up(md) {
    margin-top: 28px;
    padding-left: 38px;
  }

  @include media-up(lg) {
    margin-top: 18px;
  }
}

.submit {
  width: 100%;
  margin-top: 37px;

  @include media-up(md) {
    margin-top: 52px;
  }

  @include media-up(lg) {
    margin-top: 23px;
    max-width: 360px;
  }
}

.error {
  border-bottom: 1px solid red;
}

.inputError {
  color: red;
  font-size: 14px;
  line-height: 17px;

  @include media-up(md) {
    font-size: 18px;
    line-height: 22px;
  }
}

.inputSuccess {
  color: $color-salad;
  font-size: 14px;
  line-height: 17px;
  margin-top: 5px;

  @include media-up(md) {
    font-size: 18px;
    line-height: 22px;
  }
}

.textError {
  color: red;
}

.caret {
  border-top: 12px solid;
  border-right: 8px solid;
  border-left: 8px solid;
  border-color: $color-green transparent transparent transparent;
  transition: transform 0.3s;
}

.active {
  transform: rotate(-180deg);
}

.thanks {
  text-align: center;
}

.termModal {
  max-width: 480px;
}

.terms {
  &Heading {
    margin-bottom: 16px;
    font-size: 18px;
  }

  &Item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    & > * {
      flex: 1;
    }
  }

  &Title {
    color: rgba(51, 51, 51, 0.87);
    font-size: 12px;
  }

  &Value {
    font-size: 12px;
  }
}

.policy {
  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  p {
    margin-bottom: 12px;
  }

  a {
    color: $color-salad;
    transition: color 0.3s;

    &:hover {
      color: $color-green;
    }
  }
}

.promocodeWrapper {
  width: 97%;
  display: flex;
}

.inputPromo {
  font-size: 1.111em;
  color: #ffffff;
  background: rgba(85, 197, 182, 0.6);
  padding: 12px 17px;
  width: 70%;
  margin-bottom: 0px;
  display: none;
}

.inputPromoDisabled {
  &:not([type="checkbox"]):not([type="radio"]):not([role="combobox"]) {
    color: #ffffff;
    background-color: $color-lightgray;
  }
  font-size: 1.111em;

  padding: 12px 17px;
  width: 70%;
  margin-bottom: 0px;
  display: none;
}

.displayFlex {
  display: flex;
}

.applyPromocode {
  width: 30%;
  height: auto;
}
