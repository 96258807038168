@import "../../assets/scss/utils/variables";
@import "../../assets/scss/utils/mixins";

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  max-width: 300px;
}

.error {
  font-size: 20px;
  text-align: center;
}